import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import React from "react";
import styled, { css } from "styled-components";
import { buttonStyles, ButtonStylesProps } from "../../styles/button";

// ReadMoreLink includes `buttonStyles`, so can take its props.
// We use this when we pass it a `variation`.
type ReadMoreProps = {} & ButtonStylesProps;

const ReadMoreWrapper = styled.span<ReadMoreProps>`
    ${buttonStyles}
    position: static;

    padding: 0;
    color: ${(p) => p.theme.color.secondaryOne.main};
    vertical-align: middle;

    svg {
        transition: ${(p) => p.theme.transition("transform")};
        margin-left: 0.5em;
    }
`;

export const ReadMore = () => {
    return (
        <ReadMoreWrapper variation="text" className="read-more">
            <>
                <span>Read more</span>
                <MdChevronRight />
            </>
        </ReadMoreWrapper>
    );
};

export const hoverStyle = ({
    scaleImage,
    offsetOutline,
}: {
    scaleImage: boolean;
    offsetOutline: boolean;
}) => css`
    :hover {
        @media (prefers-reduced-motion: no-preference) {
            ${scaleImage &&
            `.featured-image {
                transform: scale(1.02);
            }`}

            .read-more {
                svg {
                    transform: translateX(0.5em);
                }
            }
        }

        @media (prefers-reduced-motion: reduce) {
            outline: 4px solid ${(p) => p.theme.color.divider.subtle};

            ${offsetOutline && `outline-offset: 8px;`}

            .read-more {
                text-decoration: underline 2px;
            }
        }
    }
`;

export const Topic = styled.p`
    font-size: ${(p) => p.theme.typography.body.fontSize};
    font-weight: ${(p) => p.theme.fontWeight.heavy};
    line-height: 1em;
    text-transform: uppercase;
    background-color: ${(p) => p.theme.color.pillButton.main};
    color: ${(p) => p.theme.color.pillButton.contrastText};
    border: 1px solid ${(p) => p.theme.color.divider.subtle};
    box-shadow: 0px 0px 5px -4px #000;
    padding: 0.5em 1em;
    border-radius: 2em;
`;
