import { graphql, useStaticQuery } from "gatsby";
import { InThePress } from "../components/InThePress";
import React from "react";
import { Head } from "../components/Head";
import { ContentGroups } from "../constants/contentGroups";

const Press: React.FC = () => {
    const data = useStaticQuery(
        graphql`
            query {
                image: file(name: { eq: "sonsOfAtlassian" }) {
                    publicURL
                }
            }
        `
    );

    return (
        <>
            <Head
                contentGroup={ContentGroups.company}
                title="In The Press"
                image={data.image.publicURL}
            />
            <InThePress />
        </>
    );
};

export default Press;
