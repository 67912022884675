import { ArticleTiles } from "../Articles/ArticleTiles";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Heading } from "../Typography/Heading";
import styled from "styled-components";
import { PageSectionContainer } from "../PageSection";

const PageSection = styled(PageSectionContainer)`
    background-color: ${(p) => p.theme.color.background.dim};
`;

const Layout = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
    margin-bottom: 4em;

    > :not(:first-child) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }
`;

const MyHeading = styled(Heading)`
    color: ${(p) => p.theme.color.primary};
    padding-bottom: 20px;
`;

export const InThePress: React.FC = () => {
    const { allSanityInThePress } = useStaticQuery(
        graphql`
            query {
                allSanityInThePress(sort: { fields: date, order: DESC }) {
                    nodes {
                        id
                        link
                        title
                        date(formatString: "MMMM YYYY")
                        featuredImage {
                            img {
                                asset {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        height: 480
                                    )
                                }
                            }
                            alt
                        }
                    }
                }
            }
        `
    );

    const articles = allSanityInThePress.nodes.map((inThePress) => ({
        date: inThePress.date,
        imageWithMeta: inThePress.featuredImage,
        title: inThePress.title,
        url: inThePress.link,
    }));

    return (
        <PageSection>
            <Layout>
                <Heading level="h1" levelStyle="h3">
                    In The Press
                </Heading>
                <ArticleTiles articles={articles} />
            </Layout>
        </PageSection>
    );
};
