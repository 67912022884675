import React from "react";
import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { gridSquares } from "../../styles/grid";
import { Paragraph } from "../Typography/Paragraph";
import { Hyperlink } from "../Link/Hyperlink";
import { Heading, HeadingLevel } from "../Typography/Heading";
import { hoverStyle, ReadMore, Topic } from "./components";

const GRID_COLUMN_SQUARES = 19;
const GRID_GAP_SQUARES = 3;
const MAX_COLUMNS = 3;
const MAX_COLUMNS_GAPS = 3;

export const GRID_MAX_WIDTH = gridSquares(
    GRID_COLUMN_SQUARES * MAX_COLUMNS + GRID_GAP_SQUARES * MAX_COLUMNS_GAPS
);

interface LayoutSettingType {
    multiColumn: boolean;
    aspectRatio: string;
    flattenBottom: boolean;
    showDescription: boolean;
    showTopic: boolean;
    clampLines: number;
    scaleImage: boolean;
    offsetOutline: boolean;
    layoutDirection: "row" | "column";
    headingStyle: HeadingLevel;
    restrictImageHeight?: number;
}

const layoutSettingTypes: { [key: string]: LayoutSettingType } = {
    full: {
        multiColumn: true,
        aspectRatio: "5 / 4",
        flattenBottom: false,
        showDescription: true,
        showTopic: true,
        clampLines: 3,
        scaleImage: true,
        offsetOutline: true,
        layoutDirection: "column",
        headingStyle: "h4",
    },
    condensed: {
        multiColumn: true,
        aspectRatio: "16 / 9",
        flattenBottom: true,
        showDescription: false,
        showTopic: true,
        clampLines: 2,
        scaleImage: false,
        offsetOutline: false,
        layoutDirection: "column",
        headingStyle: "h5",
    },
    sidebar: {
        multiColumn: false,
        aspectRatio: "1 / 1",
        flattenBottom: false,
        showDescription: false,
        showTopic: false,
        clampLines: 3,
        scaleImage: true,
        offsetOutline: true,
        layoutDirection: "row",
        headingStyle: "h6",
        restrictImageHeight: 128,
    },
} as const;

type layoutTypes = keyof typeof layoutSettingTypes;

const roundedCorners = css`
    border-radius: ${(p) => p.theme.borderRadius.l};
`;

const clampLines = (lineLimit: number) => {
    return css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: ${lineLimit ? lineLimit : 3};
    `;
};

const TextLayout = styled.div`
    width: 100%;
    padding: 0 ${gridSquares(1)} ${gridSquares(1)};
`;

const Title = styled(Heading)<{ layout: LayoutSettingType }>`
    && {
        margin-top: 48px;
        margin-bottom: 24px;
    }
    font-weight: 700; /* review h5 not bold? */
    ${(p) => clampLines(p.layout.clampLines)}
`;

const Description = styled(Paragraph)`
    margin-top: 24px;
    margin-bottom: 24px;
    ${clampLines(3)}
`;

const FeaturedImageWrapper = styled.div<{ layout: LayoutSettingType }>`
    position: relative;

    aspect-ratio: ${(p) => p.layout.aspectRatio};

    ${(p) =>
        p.layout.restrictImageHeight &&
        css`
            height: ${p.layout.restrictImageHeight}px;
        `}

    ${Topic} {
        position: absolute;
        top: 16px;
        right: 16px;
    }
`;

// For accessibility, we use the heading of the blog post as the link text.
// This component makes the whole card clickable, though.
export const HeadingLink = styled(Hyperlink)`
    /*
        This makes this "Read More" link cover the entire tile. We want the link
        text to sit in the lower left of the tile, but the clickable area to
        cover the whole tile. To do this, we use an absolute positioned :after
        pseudo-element (which is also clickable) instead of the element itself.
    */
    :after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;

const FeaturedImage = styled(GatsbyImage)<{
    $articleLayout: LayoutSettingType;
}>`
    width: 100%;
    transition: ${(p) => p.theme.transition("transform")};
    ${roundedCorners};
    overflow: hidden;
    /* This line fixes a rendering issue in Safari. The CSS property "overflow: hidden" won't work in Safari unless you add this webkit property */
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    aspect-ratio: ${(p) => p.$articleLayout.aspectRatio};

    ${(p) =>
        p.$articleLayout.flattenBottom &&
        css`
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        `}
`;

const Tile = styled.article<{ layout: LayoutSettingType }>`
    position: relative;
    background: ${(p) => p.theme.color.card.background};

    ${roundedCorners};

    ${(p) =>
        hoverStyle({
            scaleImage: p.layout.scaleImage,
            offsetOutline: p.layout.offsetOutline,
        })}

    ${(p) =>
        p.layout.layoutDirection === "row" &&
        css`
            display: flex;
            flex-direction: row;
            align-items: center;

            ${TextLayout} {
                padding-bottom: 0px;
            }

            ${Title} {
                margin-top: 0px;
            }
        `}
`;

const Ul = styled.ul<{
    length: number;
    layout: LayoutSettingType;
}>`
    display: grid;
    position: relative;
    z-index: 2;

    row-gap: 60px;
    column-gap: 60px;
    grid-template-columns: 1fr;

    ${(p) =>
        p.layout.multiColumn &&
        css`
            ${p.theme.media("sm")} {
                row-gap: 30px;
                column-gap: 30px;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            ${p.theme.media("md")} {
                row-gap: 60px;
                column-gap: 60px;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            ${p.theme.media("xxl")} {
                row-gap: 120px;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        `}
`;

interface Props {
    date: string;
    imageWithMeta: GatsbyTypes.SanityImageWithMeta;
    title: string;
    description?: string;
    path?: string;
    url?: string;
    category?: string;
    layout?: LayoutSettingType;
}

const ArticleTile: React.FC<Props> = ({
    imageWithMeta,
    title,
    description,
    path,
    url,
    category,
    layout,
}) => {
    const href = path ? `/blog/${path}/` : url;

    const gatsbyImage = imageWithMeta?.img?.asset?.gatsbyImageData;

    return (
        <Tile layout={layout}>
            {/* @ts-ignore */}
            <FeaturedImageWrapper layout={layout}>
                {gatsbyImage && (
                    <FeaturedImage
                        image={gatsbyImage}
                        alt={imageWithMeta?.alt || title}
                        $articleLayout={layout}
                        className="featured-image"
                    />
                )}
                {layout.showTopic && <Topic>{category}</Topic>}
            </FeaturedImageWrapper>
            <TextLayout>
                <HeadingLink href={href}>
                    <Title
                        level="h3"
                        levelStyle={layout.headingStyle}
                        layout={layout}
                    >
                        {title}
                    </Title>
                </HeadingLink>
                {layout.showDescription && (
                    <Description>{description}</Description>
                )}
                <ReadMore />
            </TextLayout>
        </Tile>
    );
};

interface Article {
    date: string;
    imageWithMeta: GatsbyTypes.SanityImageWithMeta;
    title: string;
    description?: string;
    path?: string;
    url?: string;
    category?: string;
}

interface PropsType {
    articles: Article[];
    layout?: layoutTypes;
}

export const ArticleTiles = ({ articles, layout = "full" }: PropsType) => {
    const layoutSettings = layoutSettingTypes[layout];

    return (
        <Ul length={articles.length} layout={layoutSettings}>
            {articles.map((article, i) => (
                <li key={`${article.url}${i}`}>
                    <ArticleTile {...article} layout={layoutSettings} />
                </li>
            ))}
        </Ul>
    );
};
